<template>
  <error-dashboard />
</template>

<script>
const ErrorDashboard = () => import('@/components/Car/ErrorDashboard')

export default {
  name: 'ErrorDashboardView',
  components: {
    ErrorDashboard
  }
}
</script>
